var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      ref: "form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _vm._t("default", null, { loading: _vm.loading }),
      _vm._v(" "),
      _vm.errorMessage
        ? _c("Alert", { attrs: { type: "warning" } }, [
            _c("p", [_vm._v(_vm._s(_vm.errorMessage))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "footer",
        [
          _vm.isDialog
            ? _c(
                "Button",
                {
                  attrs: { variant: "contained", disabled: _vm.loading },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onCancel.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._t(
            "footer",
            function () {
              return [
                _c(
                  "Button",
                  {
                    attrs: {
                      type: "submit",
                      variant: "contained",
                      color: "primary",
                      loading: _vm.loading,
                    },
                  },
                  [_vm._v(" Submit")]
                ),
              ]
            },
            { loading: _vm.loading }
          ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }