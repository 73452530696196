<template>
  <form ref="form" @submit.prevent="onSubmit">
    <slot :loading="loading" />
    <Alert v-if="errorMessage" type="warning">
      <p>{{ errorMessage }}</p>
    </Alert>
    <footer>
      <Button v-if="isDialog" variant="contained" @click.prevent="onCancel" :disabled="loading"> Cancel </Button>
      <slot name="footer" :loading="loading">
        <Button type="submit" variant="contained" color="primary" :loading="loading"> Submit</Button>
      </slot>
    </footer>
  </form>
</template>

<script>
import Button from "@/components/buttons/Generic.vue";
import Icon from "@/components/icons/Icon.vue";
import Alert from "@/components/Alert.vue";

export default {
  name: "Form",
  components: { Alert, Icon, Button },
  props: {
    action: { type: Function, required: true },
    payload: { type: Object, default: () => ({}) },
  },
  emits: ["submit", "cancel"],
  data() {
    return {
      loading: false,
      error: null,
      isDialog: false,
    };
  },
  computed: {
    errorMessage() {
      if (this.error === null) return "";
      switch (this.error.status) {
        case 400:
          return this.error.body.detail[0];
        case 422:
          const { loc, msg } = this.error.body.detail.at(0);
          return `${loc} : ${msg}`;
        case 500:
        default:
          return "An error occurred... please contact support.";
      }
    },
  },
  mounted() {
    this.isDialog = this.$refs.form?.parentNode.tagName === "DIALOG";
  },
  methods: {
    closeDialog() {
      if (this.isDialog) this.$refs.form?.parentNode.close();
    },
    async onSubmit(event) {
      this.error = null;
      this.loading = true;
      try {
        await this.action(this.payload);
        this.closeDialog();
        this.$emit("submit");
      } catch (error) {
        if (error.response) {
          const {
            response: { body, status },
          } = error;
          this.error = { body, status };
        } else {
          console.error(error);
        }
      } finally {
        this.loading = false;
      }
    },
    onCancel() {
      this.error = null;
      this.closeDialog();
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped lang="scss">
form {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 0;

  h2 {
    font-size: 1.1em !important;
    text-align: center;
    margin-block-end: 0.2em;
  }

  h3,
  h4 {
    font-size: 1em !important;
    color: var(--label);
    margin: 0;
  }

  p {
    font-size: 0.8em !important;
    margin: 0;

    &.description {
      color: var(--secondary-label);
    }

    &.error {
      color: var(--system-red);
    }
  }

  fieldset {
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      flex-direction: column;
      gap: 0.3em;
      margin: 0;
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
    gap: 0.5em;
  }

  select,
  textare,
  input {
    appearance: none;
    border: 1px solid var(--separator);
    background-color: var(--system-background);
    color: var(--label);
    outline: none;
    width: 100%;
    height: 2em;
    border-radius: 0.3em;
    padding-inline: 0.8em;

    &.invalid {
      border-color: var(--system-red);
      color: var(--system-red);
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    flex: 0 0 1.2em;
    height: 1.2em;
    width: 1.2em;
    aspect-ratio: 1;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    outline: 1px solid var(--separator);

    &:checked {
      background-color: var(--system-picsellia);
    }
  }

  input[type="radio"] {
    border: 0.2em solid var(--system-background);

    &:checked {
      outline-color: var(--system-picsellia);
    }
  }

  input[type="checkbox"] {
    border: none;

    &:checked {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
    }
  }
}
</style>
