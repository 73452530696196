var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "alert-container", class: [_vm.type] },
    [
      _c("Icon", { attrs: { name: _vm.iconName } }),
      _vm._v(" "),
      _c("div", { staticClass: "alert" }, [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }